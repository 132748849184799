<template>
  <div class="customize-main">
    <table class="customize-form" border="0" cellpadding="0" cellspacing="0">
      <tbody>
        <tr>
          <td class="text-center" rowspan="2" colspan="1">
            <img class="tbale-img1" src="@/assets/image/table/tbale-img1.jpg" />
          </td>
          <td class="text-center" rowspan="2" colspan="4">
            <h1>
              Position Adjustment Applicantion <br />中东公司员工岗位变动审批表
            </h1>
          </td>
          <td class="text-right">Application Date<br />申请日期</td>
          <td class="text-center">
            {{ ApplicationDate | tableDateFrom }}
          </td>
        </tr>
        <tr>
          <td class="text-right">Workflow Table S/N<br />表单编号</td>
          <td class="text-center">
            <p>{{ ApplicationNumber }}</p>
          </td>
        </tr>

        <tr>
          <td class="text-center underline-text">
            Employee ID <br />
            员工编码
          </td>
          <td class="text-center">
            <el-input
              v-model="EmployeeCode"
              @blur="changeEmployeeID"
              placeholder="请输入 Enter"
            />
          </td>
          <td class="text-center">
            Employee Name <br />
            员工姓名
          </td>
          <td class="text-center">
            <el-input v-model="EmployeeName" placeholder="请输入 Enter" />
          </td>
          <td class="text-center">
            Name in English<br />
            拼音姓名
          </td>
          <td class="text-center" colspan="2">
            <el-input v-model="EmployeeNameEN" placeholder="请输入 Enter" />
          </td>
        </tr>
        <tr>
          <td class="text-center">
            Nationality<br />
            国籍
          </td>
          <td class="text-center">
            <el-select
              v-model="Nationality"
              placeholder="请选择 Please select"
              filterable
              clearable
              class="card-select"
            >
              <el-option
                v-for="item in nationalityList"
                :key="item.ID"
                :label="item.CountryNameCHS"
                :value="item.ID"
              >
              </el-option>
            </el-select>
          </td>
          <td class="text-center">
            IQAMA National ID <br />
            暂住证号
          </td>
          <td class="text-center">
            <el-input v-model="IQAMAID" placeholder="请输入 Enter" />
          </td>
          <td class="text-center">
            Employment Type<br />
            用工形式
          </td>
          <td class="text-center" colspan="2">
            <el-select
              v-model="EmploymentType"
              placeholder="请选择 Please select"
              filterable
              clearable
              class="card-select"
            >
              <el-option
                v-for="item in employmentForm"
                :key="item.ID"
                :label="item.EmploymentName"
                :value="item.ID"
              >
              </el-option>
            </el-select>
          </td>
        </tr>
        <tr>
          <td class="text-center">
            Project Name<br />
            项目部信息
          </td>
          <td class="text-center">
            <el-select
              v-model="ProjectID"
              placeholder="请选择 Please select"
              filterable
              clearable
              class="card-select"
              @change="changeProjectID"
            >
              <el-option
                v-for="item in projectList"
                :key="item.ProjectID"
                :label="item.ProjectName"
                :value="item.ProjectID"
              >
              </el-option>
            </el-select>
          </td>
          <td class="text-center">
            Sub-project Name<br />
            分项目部信息
          </td>
          <td class="text-center">
            <el-select
              v-model="SubProjectID"
              placeholder="请选择 Please select"
              filterable
              clearable
              class="card-select"
            >
              <el-option
                v-for="item in subPojectList"
                :key="item.ProjectID"
                :label="item.ProjectName"
                :value="item.ProjectID"
              >
              </el-option>
            </el-select>
          </td>
          <td class="text-center">
            Working Unit<br />
            用工单位
          </td>
          <td class="text-center" colspan="2">
            <el-select
              v-model="CompanyID"
              placeholder="请选择 Please select"
              filterable
              clearable
              class="card-select"
            >
              <el-option
                v-for="item in companyList"
                :key="item.ID"
                :label="item.CompanyCHS"
                :value="item.ID"
              >
              </el-option>
            </el-select>
          </td>
        </tr>

        <tr>
          <td class="text-center">
            Comparison Scale<br />
            岗位对比
          </td>
          <td class="text-center" colspan="2">Position Discipline 岗位系统</td>
          <td class="text-center" colspan="2">Job Position 岗位名称</td>
          <td class="text-center" colspan="2">Job Level 岗位层级</td>
        </tr>
        <tr>
          <td class="text-center">
            Current<br />
            原岗位
          </td>
          <td class="text-center" colspan="2">
            <el-select
              v-model="CurrentPositionDiscipline"
              placeholder="请选择 Please select"
              filterable
              clearable
              class="card-select"
            >
              <el-option
                v-for="item in positionCategory"
                :key="item.ID"
                :label="item.NameCHS"
                :value="item.ID"
              >
              </el-option>
            </el-select>
          </td>
          <td class="text-center" colspan="2">
            <el-select
              v-model="CurrentJobPosition"
              placeholder="请选择 Please select"
              filterable
              clearable
              class="card-select"
            >
              <el-option
                v-for="item in positionList"
                :key="item.ID"
                :label="item.PositionsCHS"
                :value="item.ID"
              >
              </el-option>
            </el-select>
          </td>
          <td class="text-center" colspan="2">
            <el-select
              v-model="CurrentJobLevel"
              placeholder="请选择 Please select"
              filterable
              clearable
              class="card-select"
            >
              <el-option
                v-for="item in positionLevel"
                :key="item.ID"
                :label="item.NameCHS"
                :value="item.ID"
              >
              </el-option>
            </el-select>
          </td>
        </tr>
        <tr>
          <td class="text-center">
            New<br />
            拟调整岗位
          </td>
          <td class="text-center" colspan="2">
            <el-select
              v-model="NewPositionDiscipline"
              placeholder="请选择 Please select"
              filterable
              clearable
              class="card-select"
            >
              <el-option
                v-for="item in positionCategory"
                :key="item.ID"
                :label="item.NameCHS"
                :value="item.ID"
              >
              </el-option>
            </el-select>
          </td>
          <td class="text-center" colspan="2">
            <el-select
              v-model="NewJobPosition"
              placeholder="请选择 Please select"
              filterable
              clearable
              class="card-select"
            >
              <el-option
                v-for="item in positionList"
                :key="item.ID"
                :label="item.PositionsCHS"
                :value="item.ID"
              >
              </el-option>
            </el-select>
          </td>
          <td class="text-center" colspan="2">
            <el-select
              v-model="NewJobLevel"
              placeholder="请选择 Please select"
              filterable
              clearable
              class="card-select"
            >
              <el-option
                v-for="item in positionLevel"
                :key="item.ID"
                :label="item.NameCHS"
                :value="item.ID"
              >
              </el-option>
            </el-select>
          </td>
        </tr>
        <tr>
          <td class="text-center">
            Reason of Position Adjustment <br />岗位变动原因
          </td>
          <td class="text-center" colspan="6">
            <el-input
              type="textarea"
              v-model="ReasonofPositionAdjustment"
              :rows="3"
              placeholder="请输入 Enter"
            ></el-input>
          </td>
        </tr>
        <tr>
          <td class="text-center" colspan="4">
            Requirements for Reorientation Position新岗位要求
          </td>
          <td class="text-center" colspan="3">Employee Status 员工匹配情况</td>
        </tr>
        <tr>
          <td class="text-center">Education <br />学历</td>
          <td class="text-center" colspan="3">
            <el-input
              type="textarea"
              v-model="EducationforReorientationPosition"
              :rows="2"
              placeholder="请输入 Enter"
            ></el-input>
          </td>
          <td class="text-center" colspan="3">
            <el-radio-group v-model="EmployeeStatusforEducation">
              <el-radio :label="1">合格 Qualified</el-radio>
              <el-radio :label="2">不合格 Unqualified</el-radio>
            </el-radio-group>
          </td>
        </tr>
        <tr>
          <td class="text-center">
            Language <br />
            语言
          </td>
          <td class="text-center" colspan="3">
            <el-input
              type="textarea"
              v-model="LanguageforReorientationPosition"
              :rows="2"
              placeholder="请输入 Enter"
            ></el-input>
          </td>
          <td class="text-center" colspan="3">
            <el-radio-group v-model="EmployeeStatusforLanguage">
              <el-radio :label="1">合格 Qualified</el-radio>
              <el-radio :label="2">不合格 Unqualified</el-radio>
            </el-radio-group>
          </td>
        </tr>
        <tr>
          <td class="text-center">
            Computer <br />
            办公软件
          </td>
          <td class="text-center" colspan="3">
            <el-input
              type="textarea"
              v-model="ComputerforReorientationPosition"
              :rows="2"
              placeholder="请输入 Enter"
            ></el-input>
          </td>
          <td class="text-center" colspan="3">
            <el-radio-group v-model="EmployeeStatusforComputer">
              <el-radio :label="1">合格 Qualified</el-radio>
              <el-radio :label="2">不合格 Unqualified</el-radio>
            </el-radio-group>
          </td>
        </tr>
        <tr>
          <td class="text-center">
            Certification<br />
            职称（持证）
          </td>
          <td class="text-center" colspan="3">
            <el-input
              type="textarea"
              v-model="CertificationforReorientationPosition"
              :rows="2"
              placeholder="请输入 Enter"
            ></el-input>
          </td>
          <td class="text-center" colspan="3">
            <el-radio-group v-model="EmployeeStatusforCertification">
              <el-radio :label="1">合格 Qualified</el-radio>
              <el-radio :label="2">不合格 Unqualified</el-radio>
            </el-radio-group>
          </td>
        </tr>
        <tr>
          <td class="text-center">
            Working Experience<br />
            工作经验
          </td>
          <td class="text-center" colspan="3">
            <el-input
              type="textarea"
              v-model="WorkingExperienceforReorientationPosition"
              :rows="2"
              placeholder="请输入 Enter"
            ></el-input>
          </td>
          <td class="text-center" colspan="3">
            <el-radio-group v-model="EmployeeStatusforWorkingExperience">
              <el-radio :label="1">合格 Qualified</el-radio>
              <el-radio :label="2">不合格 Unqualified</el-radio>
            </el-radio-group>
          </td>
        </tr>
        <tr>
          <td class="text-center">
            Ohers<br />
            其他要求
          </td>
          <td class="text-center" colspan="3">
            <el-input
              type="textarea"
              v-model="OthersforReorientationPosition"
              :rows="2"
              placeholder="请输入 Enter"
            ></el-input>
          </td>
          <td class="text-center" colspan="3">
            <el-radio-group v-model="EmployeeStatusforOthers">
              <el-radio :label="1">合格 Qualified</el-radio>
              <el-radio :label="2">不合格 Unqualified</el-radio>
            </el-radio-group>
          </td>
        </tr>
        <tr>
          <td class="text-center text-size" colspan="2">
            Working Department<br />
            用工单位审核
          </td>
          <td class="text-left text-size" colspan="5">
            <el-input
              type="textarea"
              v-model="WorkingDeptRemark"
              :rows="2"
              placeholder="请输入 Enter"
            ></el-input>
            <div class="text-box">
              <div class="sign-box">
                <span>签字Sign：</span
                ><img class="sign" v-if="WorkingDept" :src="WorkingDept" />
              </div>
              <div>
                <span>日期Date：</span><span>{{ WorkingDeptDate }}</span>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td class="text-center text-size" colspan="2">
            HR officer of SNEMEC HR Department<br />
            中东公司人力资源部业务经办人员
          </td>
          <td class="text-left text-size" colspan="5">
            <el-input
              type="textarea"
              v-model="HROfficerofSNEMECHRDepartmentRemark"
              :rows="2"
              placeholder="请输入 Enter"
            ></el-input>
            <div class="text-box">
              <div class="sign-box">
                <span>签字Sign：</span
                ><img
                  class="sign"
                  v-if="HROfficerofSNEMECHRDepartment"
                  :src="HROfficerofSNEMECHRDepartment"
                />
              </div>
              <div>
                <span>日期Date：</span
                ><span>{{ HROfficerofSNEMECHRDepartmentDate }}</span>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td class="text-center text-size" colspan="2">
            Manager of SNEMEC HR Department<br />
            中东公司人力资源部经理
          </td>
          <td class="text-left text-size" colspan="5">
            <el-input
              type="textarea"
              v-model="ManagerofSNEMECHRDepartmentRemark"
              :rows="2"
              placeholder="请输入 Enter"
            ></el-input>
            <div class="text-box">
              <div class="sign-box">
                <span>签字Sign：</span
                ><img
                  class="sign"
                  v-if="ManagerofSNEMECHRDepartment"
                  :src="ManagerofSNEMECHRDepartment"
                />
              </div>
              <div>
                <span>日期Date：</span
                ><span>{{ ManagerofSNEMECHRDepartmentDate }}</span>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td class="text-center text-size" colspan="2">
            Authorized Manager of SNEMEC<br />
            中东公司业务分管领导
          </td>
          <td class="text-left text-size" colspan="5">
            <el-input
              type="textarea"
              v-model="AuthorizedManagerofSNEMECRemark"
              :rows="2"
              placeholder="请输入 Enter"
            ></el-input>
            <div class="text-box">
              <div class="sign-box">
                <span>签字Sign：</span
                ><img
                  class="sign"
                  v-if="AuthorizedManagerofSNEMEC"
                  :src="AuthorizedManagerofSNEMEC"
                />
              </div>
              <div>
                <span>日期Date：</span
                ><span>{{ AuthorizedManagerofSNEMECDate }}</span>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td class="text-center" colspan="7">
            The following content is only displayed online and is not in the
            print area 以下内容仅线上流转显示，非打印区域
          </td>
        </tr>
        <tr>
          <td class="text-center" rowspan="2">
            Douments Upload<br />
            材料上传区域
          </td>
          <td class="text-left" colspan="6">
            * The level of medical insurance shall be implemented in accordance
            with the relevant system requirements of the company. In special
            circumstances, if apply beyond the level, please upload a authorized
            clarification of the situation.<br />
            *
            员工医保等级按照公司相关制度要求执行，特殊情况需越级购买请上传情况说明。
          </td>
        </tr>
        <tr>
          <td class="text-center" colspan="1">
            <div class="shenpi shenpi-color" v-if="UploadingDocument1">
              <div @click="download(UploadingDocument1)">
                {{ UploadingDocument1 | stringFormater }}
              </div>
              <span><i class="el-icon-delete" @click="deleteItem(1)"></i></span>
            </div>
            <el-upload
              action="#"
              class="card-upload"
              :auto-upload="true"
              :http-request="uploadAttachment1"
              ref="uploadRef"
              v-else
            >
              <div class="shenpi">
                Up loading document 1<br />
                材料上传1<br />
                <span class="shenpi-tips">点击上传</span>
              </div>
            </el-upload>
          </td>
          <td class="text-center" colspan="1">
            <div class="shenpi shenpi-color" v-if="UploadingDocument2">
              <div @click="download(UploadingDocument2)">
                {{ UploadingDocument2 | stringFormater }}
              </div>
              <span><i class="el-icon-delete" @click="deleteItem(2)"></i></span>
            </div>
            <el-upload
              action="#"
              class="card-upload"
              :auto-upload="true"
              :http-request="uploadAttachment2"
              ref="uploadRef"
              v-else
            >
              <div class="shenpi">
                Up loading document 2<br />
                材料上传2<br />
                <span class="shenpi-tips">点击上传</span>
              </div>
            </el-upload>
          </td>
          <td class="text-center" colspan="1">
            <div class="shenpi shenpi-color" v-if="UploadingDocument3">
              <div @click="download(UploadingDocument3)">
                {{ UploadingDocument3 | stringFormater }}
              </div>
              <span><i class="el-icon-delete" @click="deleteItem(3)"></i></span>
            </div>
            <el-upload
              action="#"
              class="card-upload"
              :auto-upload="true"
              :http-request="uploadAttachment3"
              ref="uploadRef"
              v-else
            >
              <div class="shenpi">
                Up loading document 3<br />
                材料上传3<br />
                <span class="shenpi-tips">点击上传</span>
              </div>
            </el-upload>
          </td>
          <td class="text-center" colspan="1">
            <div class="shenpi shenpi-color" v-if="UploadingDocument4">
              <div @click="download(UploadingDocument4)">
                {{ UploadingDocument4 | stringFormater }}
              </div>
              <span><i class="el-icon-delete" @click="deleteItem(4)"></i></span>
            </div>
            <el-upload
              action="#"
              class="card-upload"
              :auto-upload="true"
              :http-request="uploadAttachment4"
              ref="uploadRef"
              v-else
            >
              <div class="shenpi">
                Up loading document 4<br />
                材料上传4<br />
                <span class="shenpi-tips">点击上传</span>
              </div>
            </el-upload>
          </td>
          <td class="text-center" colspan="2">
            <div class="shenpi shenpi-color" v-if="UploadingDocument5">
              <div @click="download(UploadingDocument5)">
                {{ UploadingDocument5 | stringFormater }}
              </div>
              <span><i class="el-icon-delete" @click="deleteItem(5)"></i></span>
            </div>
            <el-upload
              action="#"
              class="card-upload"
              :auto-upload="true"
              :http-request="uploadAttachment5"
              ref="uploadRef"
              v-else
            >
              <div class="shenpi">
                Up loading document 5<br />
                材料上传5<br />
                <span class="shenpi-tips">点击上传</span>
              </div>
            </el-upload>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { getNewDate } from "@/utils/date";
import {
  getProjectAll,
  getCompanyAll,
  uploadAttachment,
  getProjectsChildren,
  allPosition,
  allPositionCategory,
} from "@/api/user";
import {
  getGeneralBasic,
  getUsersCode,
  getEmploymentFormAll,
  getPositionLevelAll,
} from "@/api/table";
export default {
  filters: {
    tableDateFrom(data) {
      if (data) {
        data = data.slice(0, 10);
      }
      return data;
    },
    projectFormater(id, data) {
      const item = data.find((item) => {
        return item.ProjectID === id;
      });
      return item ? item.ProjectName : "";
    },
    positionFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.PositionsCHS : null;
    },
    companyFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.CompanyCHS : null;
    },
    nationalityFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.CountryNameCHS : null;
    },
    labelFormater(id, data) {
      const item = data.find((item) => {
        return item.value === id;
      });
      return item ? item.label : null;
    },
    purchaseOrderFrom(id, data) {
      const item = data.find((item) => {
        return item.KeyID === id;
      });
      return item ? item.AbbreviationName : null;
    },
    purchaseOrderFrom2(id, data) {
      const item = data.find((item) => {
        return item.KeyID === id;
      });
      return item ? item.CRNO : null;
    },
    stringFormater(data) {
      let name1 = process.env.VUE_APP_SERVER + "upload/";
      let index1 = data.indexOf(name1);
      data = data.slice(index1 + name1.length, data.length - 1);
      const index2 = data.indexOf("/");
      const index3 = data.lastIndexOf(".");
      data = data.slice(index2 + 1, index3);
      return data;
    },
    englishLetterFrom(id, data) {
      let name = "";
      if (!id && id !== 0) {
        return;
      }
      name = data[id];
      return name;
    },
  },
  data() {
    return {
      ApplicationDate: null,
      ApplicationNumber: null,
      ID: null,
      KeyID: null,
      EmployeeID: null,
      EmployeeCode: null,
      EmployeeName: null,
      EmployeeNameEN: null,
      Nationality: null,
      NationalityName: null,
      IQAMAID: null,
      PassportCode: null,
      EmploymentType: null,
      EmploymentTypeName: null,
      ProjectID: null,
      ProjectName: null,
      SubProjectID: null,
      SubProjectName: null,
      CompanyID: null,
      WorkingUnit: null,
      DepartmentID: null,
      WorkingDepartment: null,
      CurrentPositionDiscipline: null,
      CurrentPositionDisciplineName: null,
      CurrentJobPosition: null,
      CurrentJobPositionName: null,
      CurrentJobLevel: null,
      CurrentJobLevelName: null,
      NewPositionDiscipline: null,
      NewPositionDisciplineName: null,
      NewJobPosition: null,
      NewJobPositionName: null,
      NewJobLevel: null,
      NewJobLevelName: null,
      SPONSOR: null,
      ReasonofPositionAdjustment: null,
      EducationforReorientationPosition: null,
      EmployeeStatusforEducation: null,
      LanguageforReorientationPosition: null,
      EmployeeStatusforLanguage: null,
      ComputerforReorientationPosition: null,
      EmployeeStatusforComputer: null,
      CertificationforReorientationPosition: null,
      EmployeeStatusforCertification: null,
      WorkingExperienceforReorientationPosition: null,
      EmployeeStatusforWorkingExperience: null,
      OthersforReorientationPosition: null,
      EmployeeStatusforOthers: null,
      Remark: null,
      EditState: null,
      UploadingDocument1: null,
      UploadingDocument2: null,
      UploadingDocument3: null,
      UploadingDocument4: null,
      UploadingDocument5: null,
      costAllocationItem: {
        ID: null,
        KeyID: null,
        MainKeyID: null,
        DependentName: null,
        DateofBirth: null,
        BorderNumber: null,
        IQAMAID: null,
        PhoneNOinWorkingCountry: null,
        DependentsRelationship: null,
        ClassID: null,
      },

      projectList: [],
      subPojectList: [],
      companyList: [],
      positionList: [],
      CostAllocationDetails: [],
      positionCategoryList: [],
      purchaseOrdersList: [],
      nationalityList: [],
      Marriages: [],
      departmentList: [],
      employmentForm: [],
      positionCategory: [],
      positionLevel: [],
      WorkingDept: null,
      WorkingDeptRemark: null,
      WorkingDeptDate: null,
      HROfficerofSNEMECHRDepartment: null,
      HROfficerofSNEMECHRDepartmentRemark: null,
      HROfficerofSNEMECHRDepartmentDate: null,
      ManagerofSNEMECHRDepartment: null,
      ManagerofSNEMECHRDepartmentRemark: null,
      ManagerofSNEMECHRDepartmentDate: null,
      AuthorizedManagerofSNEMEC: null,
      AuthorizedManagerofSNEMECRemark: null,
      AuthorizedManagerofSNEMECDate: null,
    };
  },
  methods: {
    getNew() {
      this.ApplicationDate = getNewDate();
    },
    addCostAllocation(EditState) {
      const data = {
        ID: this.ID,
        KeyID: this.KeyID,
        EmployeeID: this.EmployeeID,
        EmployeeCode: this.EmployeeCode,
        EmployeeName: this.EmployeeName,
        EmployeeNameEN: this.EmployeeNameEN,
        Nationality: this.Nationality,
        NationalityName: this.NationalityName,
        IQAMAID: this.IQAMAID,
        PassportCode: this.PassportCode,
        EmploymentType: this.EmploymentType,
        EmploymentTypeName: this.EmploymentTypeName,
        ProjectID: this.ProjectID,
        ProjectName: this.ProjectName,
        SubProjectID: this.SubProjectID,
        SubProjectName: this.SubProjectName,
        CompanyID: this.CompanyID,
        WorkingUnit: this.WorkingUnit,
        DepartmentID: this.DepartmentID,
        WorkingDepartment: this.WorkingDepartment,
        CurrentPositionDiscipline: this.CurrentPositionDiscipline,
        CurrentPositionDisciplineName: this.CurrentPositionDisciplineName,
        CurrentJobPosition: this.CurrentJobPosition,
        CurrentJobPositionName: this.CurrentJobPositionName,
        CurrentJobLevel: this.CurrentJobLevel,
        CurrentJobLevelName: this.CurrentJobLevelName,
        NewPositionDiscipline: this.NewPositionDiscipline,
        NewPositionDisciplineName: this.NewPositionDisciplineName,
        NewJobPosition: this.NewJobPosition,
        NewJobPositionName: this.NewJobPositionName,
        NewJobLevel: this.NewJobLevel,
        NewJobLevelName: this.NewJobLevelName,
        SPONSOR: this.SPONSOR,
        ReasonofPositionAdjustment: this.ReasonofPositionAdjustment,
        EducationforReorientationPosition:
          this.EducationforReorientationPosition,
        EmployeeStatusforEducation: this.EmployeeStatusforEducation,
        LanguageforReorientationPosition: this.LanguageforReorientationPosition,
        EmployeeStatusforLanguage: this.EmployeeStatusforLanguage,
        ComputerforReorientationPosition: this.ComputerforReorientationPosition,
        EmployeeStatusforComputer: this.EmployeeStatusforComputer,
        CertificationforReorientationPosition:
          this.CertificationforReorientationPosition,
        EmployeeStatusforCertification: this.EmployeeStatusforCertification,
        WorkingExperienceforReorientationPosition:
          this.WorkingExperienceforReorientationPosition,
        EmployeeStatusforWorkingExperience:
          this.EmployeeStatusforWorkingExperience,
        OthersforReorientationPosition: this.OthersforReorientationPosition,
        EmployeeStatusforOthers: this.EmployeeStatusforOthers,
        Remark: this.Remark,
        EditState: this.EditState,
        UploadingDocument1: this.UploadingDocument1,
        UploadingDocument2: this.UploadingDocument2,
        UploadingDocument3: this.UploadingDocument3,
        UploadingDocument4: this.UploadingDocument4,
        UploadingDocument5: this.UploadingDocument5,
        EditState,
        Details: [],
      };

      return data;
    },
    geteditData(params) {
      console.log("params", params);
      this.ApplicationDate = params.CreateDate;
      this.ApplicationNumber = params.ApplicationNumber;
      this.ID = params.ID;
      this.KeyID = params.KeyID;
      this.EmployeeID = params.EmployeeID;
      this.EmployeeCode = params.EmployeeCode;
      this.EmployeeName = params.EmployeeName;
      this.EmployeeNameEN = params.EmployeeNameEN;
      this.Nationality = params.Nationality;
      this.NationalityName = params.NationalityName;
      this.IQAMAID = params.IQAMAID;
      this.PassportCode = params.PassportCode;
      this.EmploymentType = params.EmploymentType;
      this.EmploymentTypeName = params.EmploymentTypeName;
      this.ProjectID = params.ProjectID;
      this.ProjectName = params.ProjectName;
      this.SubProjectID = params.SubProjectID;
      this.SubProjectName = params.SubProjectName;
      this.CompanyID = params.CompanyID;
      this.WorkingUnit = params.WorkingUnit;
      this.DepartmentID = params.DepartmentID;
      this.WorkingDepartment = params.WorkingDepartment;
      this.CurrentPositionDiscipline = params.CurrentPositionDiscipline;
      this.CurrentPositionDisciplineName = params.CurrentPositionDisciplineName;
      this.CurrentJobPosition = params.CurrentJobPosition;
      this.CurrentJobPositionName = params.CurrentJobPositionName;
      this.CurrentJobLevel = params.CurrentJobLevel;
      this.CurrentJobLevelName = params.CurrentJobLevelName;
      this.NewPositionDiscipline = params.NewPositionDiscipline;
      this.NewPositionDisciplineName = params.NewPositionDisciplineName;
      this.NewJobPosition = params.NewJobPosition;
      this.NewJobPositionName = params.NewJobPositionName;
      this.NewJobLevel = params.NewJobLevel;
      this.NewJobLevelName = params.NewJobLevelName;
      this.SPONSOR = params.SPONSOR;
      this.ReasonofPositionAdjustment = params.ReasonofPositionAdjustment;
      this.EducationforReorientationPosition =
        params.EducationforReorientationPosition;
      this.EmployeeStatusforEducation = params.EmployeeStatusforEducation;
      this.LanguageforReorientationPosition =
        params.LanguageforReorientationPosition;
      this.EmployeeStatusforLanguage = params.EmployeeStatusforLanguage;
      this.ComputerforReorientationPosition =
        params.ComputerforReorientationPosition;
      this.EmployeeStatusforComputer = params.EmployeeStatusforComputer;
      this.CertificationforReorientationPosition =
        params.CertificationforReorientationPosition;
      this.EmployeeStatusforCertification =
        params.EmployeeStatusforCertification;
      this.WorkingExperienceforReorientationPosition =
        params.WorkingExperienceforReorientationPosition;
      this.EmployeeStatusforWorkingExperience =
        params.EmployeeStatusforWorkingExperience;
      this.OthersforReorientationPosition =
        params.OthersforReorientationPosition;
      this.EmployeeStatusforOthers = params.EmployeeStatusforOthers;
      this.Remark = params.Remark;
      this.EditState = params.EditState;
      this.UploadingDocument1 = params.UploadingDocument1;
      this.UploadingDocument2 = params.UploadingDocument2;
      this.UploadingDocument3 = params.UploadingDocument3;
      this.UploadingDocument4 = params.UploadingDocument4;
      this.UploadingDocument5 = params.UploadingDocument5;
      this.WorkingDept = params.WorkingDept;
      this.WorkingDeptRemark = params.WorkingDeptRemark;
      this.WorkingDeptDate = params.WorkingDeptDate;
      this.HROfficerofSNEMECHRDepartment = params.HROfficerofSNEMECHRDepartment;
      this.HROfficerofSNEMECHRDepartmentRemark =
        params.HROfficerofSNEMECHRDepartmentRemark;
      this.HROfficerofSNEMECHRDepartmentDate =
        params.HROfficerofSNEMECHRDepartmentDate;
      this.ManagerofSNEMECHRDepartment = params.ManagerofSNEMECHRDepartment;
      this.ManagerofSNEMECHRDepartmentRemark =
        params.ManagerofSNEMECHRDepartmentRemark;
      this.ManagerofSNEMECHRDepartmentDate =
        params.ManagerofSNEMECHRDepartmentDate;
      this.AuthorizedManagerofSNEMEC = params.AuthorizedManagerofSNEMEC;
      this.AuthorizedManagerofSNEMECRemark =
        params.AuthorizedManagerofSNEMECRemark;
      this.AuthorizedManagerofSNEMECDate = params.AuthorizedManagerofSNEMECDate;
      this.getProjectsChildren(this.ProjectID);
    },
    clearData() {
      this.subPojectList = [];
      this.ApplicationDate = null;
      this.ApplicationNumber = null;
      this.ID = null;
      this.KeyID = null;
      this.EmployeeID = null;
      this.EmployeeCode = null;
      this.EmployeeName = null;
      this.EmployeeNameEN = null;
      this.Nationality = null;
      this.NationalityName = null;
      this.IQAMAID = null;
      this.PassportCode = null;
      this.EmploymentType = null;
      this.EmploymentTypeName = null;
      this.ProjectID = null;
      this.ProjectName = null;
      this.SubProjectID = null;
      this.SubProjectName = null;
      this.CompanyID = null;
      this.WorkingUnit = null;
      this.DepartmentID = null;
      this.WorkingDepartment = null;
      this.CurrentPositionDiscipline = null;
      this.CurrentPositionDisciplineName = null;
      this.CurrentJobPosition = null;
      this.CurrentJobPositionName = null;
      this.CurrentJobLevel = null;
      this.CurrentJobLevelName = null;
      this.NewPositionDiscipline = null;
      this.NewPositionDisciplineName = null;
      this.NewJobPosition = null;
      this.NewJobPositionName = null;
      this.NewJobLevel = null;
      this.NewJobLevelName = null;
      this.SPONSOR = null;
      this.ReasonofPositionAdjustment = null;
      this.EducationforReorientationPosition = null;
      this.EmployeeStatusforEducation = null;
      this.LanguageforReorientationPosition = null;
      this.EmployeeStatusforLanguage = null;
      this.ComputerforReorientationPosition = null;
      this.EmployeeStatusforComputer = null;
      this.CertificationforReorientationPosition = null;
      this.EmployeeStatusforCertification = null;
      this.WorkingExperienceforReorientationPosition = null;
      this.EmployeeStatusforWorkingExperience = null;
      this.OthersforReorientationPosition = null;
      this.EmployeeStatusforOthers = null;
      this.Remark = null;
      this.EditState = null;
      this.UploadingDocument1 = null;
      this.UploadingDocument2 = null;
      this.UploadingDocument3 = null;
      this.UploadingDocument4 = null;
      this.UploadingDocument5 = null;
      this.WorkingDept = null;
      this.WorkingDeptRemark = null;
      this.WorkingDeptDate = null;
      this.HROfficerofSNEMECHRDepartment = null;
      this.HROfficerofSNEMECHRDepartmentRemark = null;
      this.HROfficerofSNEMECHRDepartmentDate = null;
      this.ManagerofSNEMECHRDepartment = null;
      this.ManagerofSNEMECHRDepartmentRemark = null;
      this.ManagerofSNEMECHRDepartmentDate = null;
      this.AuthorizedManagerofSNEMEC = null;
      this.AuthorizedManagerofSNEMECRemark = null;
      this.AuthorizedManagerofSNEMECDate = null;
    },
    //通过员工编码读取数据
    async changeEmployeeID() {
      const value = this.EmployeeCode;
      if (!value) {
        return;
      }
      let userInfo = null;
      await getUsersCode({ code: value, codeType: 2 }).then((res) => {
        if (res.status === 200 && res.response) {
          userInfo = res.response;
        }
      });
      if (userInfo) {
        console.log("userInfo", userInfo);

        this.Nationality = userInfo.CountryID;

        this.EmployeeName = userInfo.ChineseName;
        this.EmployeeNameEN = userInfo.EnglishName;
        this.ProjectID = userInfo.ProjectID;
        this.SubProjectID = userInfo.SubProjectID;
        this.CompanyID = userInfo.CompanyID;
        this.CurrentJobPosition = userInfo.PositionID;
        this.EmploymentType = userInfo.EmploymentType;
        this.IQAMAID = userInfo.IQAMAID;
        this.CurrentPositionDiscipline = userInfo.PositionCategoryID;
        this.CurrentJobLevel = userInfo.PositionLevelID;

        this.getProjectsChildren(userInfo.ProjectID);

        if (this.ClassID) {
          this.DependentsCurrentClassLevel = this.ClassID;
          this.CostAllocationDetails.forEach((_, index, Array) => {
            Array[index].ClassID = this.ClassID;
          });
          this.costAllocationItem.ClassID = this.ClassID;
        }
      }
    },
    //通过选中项目获取分项目
    changeProjectID() {
      this.subPojectList = [];
      this.SubProjectID = null;
      if (this.ProjectID || this.ProjectID === 0) {
        this.getProjectsChildren(this.ProjectID);
      }
    },

    getProjectsChildren(id) {
      getProjectsChildren({ id }).then((res) => {
        if (res.status === 200) {
          this.subPojectList = res.response;
        }
      });
    },
    deleteItem(index) {
      switch (index) {
        case 1:
          this.UploadingDocument1 = null;
          break;
        case 2:
          this.UploadingDocument2 = null;
          break;
        case 3:
          this.UploadingDocument3 = null;
          break;
        case 4:
          this.UploadingDocument4 = null;
          break;
        case 5:
          this.UploadingDocument5 = null;
          break;
      }
    },
    uploadAttachment1(file) {
      const data = file.file;
      var img = data.name.substring(data.name.lastIndexOf(".") + 1);
      const suffix = img === "jpg";
      const suffix2 = img === "png";
      const suffix3 = img === "jpeg";
      const suffix4 = img === "pdf";
      const suffix5 = img === "xls";
      const suffix6 = img === "xlsx";
      const suffix7 = img === "rar";
      const suffix8 = img === "zip";
      if (
        !suffix &&
        !suffix2 &&
        !suffix3 &&
        !suffix4 &&
        !suffix5 &&
        !suffix6 &&
        !suffix7 &&
        !suffix8
      ) {
        this.$message.error(
          "只能上传后缀为jpg、png、jpeg、pdf、xls、xlsx、rar、zip的文件!"
        );
        return;
      }
      const formData = new FormData();
      formData.append("Files", data);
      uploadAttachment(formData).then((res) => {
        if (res.status === 200) {
          this.UploadingDocument1 =
            process.env.VUE_APP_SERVER + "upload/" + res.response;
        }
      });
    },
    uploadAttachment2(file) {
      const data = file.file;
      var img = data.name.substring(data.name.lastIndexOf(".") + 1);
      const suffix = img === "jpg";
      const suffix2 = img === "png";
      const suffix3 = img === "jpeg";
      const suffix4 = img === "pdf";
      const suffix5 = img === "xls";
      const suffix6 = img === "xlsx";
      const suffix7 = img === "rar";
      const suffix8 = img === "zip";
      if (
        !suffix &&
        !suffix2 &&
        !suffix3 &&
        !suffix4 &&
        !suffix5 &&
        !suffix6 &&
        !suffix7 &&
        !suffix8
      ) {
        this.$message.error(
          "只能上传后缀为jpg、png、jpeg、pdf、xls、xlsx、rar、zip的文件!"
        );
        return;
      }
      const formData = new FormData();
      formData.append("Files", data);
      uploadAttachment(formData).then((res) => {
        if (res.status === 200) {
          this.UploadingDocument2 =
            process.env.VUE_APP_SERVER + "upload/" + res.response;
        }
      });
    },
    uploadAttachment3(file) {
      const data = file.file;
      var img = data.name.substring(data.name.lastIndexOf(".") + 1);
      const suffix = img === "jpg";
      const suffix2 = img === "png";
      const suffix3 = img === "jpeg";
      const suffix4 = img === "pdf";
      const suffix5 = img === "xls";
      const suffix6 = img === "xlsx";
      const suffix7 = img === "rar";
      const suffix8 = img === "zip";
      if (
        !suffix &&
        !suffix2 &&
        !suffix3 &&
        !suffix4 &&
        !suffix5 &&
        !suffix6 &&
        !suffix7 &&
        !suffix8
      ) {
        this.$message.error(
          "只能上传后缀为jpg、png、jpeg、pdf、xls、xlsx、rar、zip的文件!"
        );
        return;
      }
      const formData = new FormData();
      formData.append("Files", data);
      uploadAttachment(formData).then((res) => {
        if (res.status === 200) {
          this.UploadingDocument3 =
            process.env.VUE_APP_SERVER + "upload/" + res.response;
        }
      });
    },
    uploadAttachment4(file) {
      const data = file.file;
      var img = data.name.substring(data.name.lastIndexOf(".") + 1);
      const suffix = img === "jpg";
      const suffix2 = img === "png";
      const suffix3 = img === "jpeg";
      const suffix4 = img === "pdf";
      const suffix5 = img === "xls";
      const suffix6 = img === "xlsx";
      const suffix7 = img === "rar";
      const suffix8 = img === "zip";
      if (
        !suffix &&
        !suffix2 &&
        !suffix3 &&
        !suffix4 &&
        !suffix5 &&
        !suffix6 &&
        !suffix7 &&
        !suffix8
      ) {
        this.$message.error(
          "只能上传后缀为jpg、png、jpeg、pdf、xls、xlsx、rar、zip的文件!"
        );
        return;
      }
      const formData = new FormData();
      formData.append("Files", data);
      uploadAttachment(formData).then((res) => {
        if (res.status === 200) {
          this.UploadingDocument4 =
            process.env.VUE_APP_SERVER + "upload/" + res.response;
        }
      });
    },
    uploadAttachment5(file) {
      const data = file.file;
      var img = data.name.substring(data.name.lastIndexOf(".") + 1);
      const suffix = img === "jpg";
      const suffix2 = img === "png";
      const suffix3 = img === "jpeg";
      const suffix4 = img === "pdf";
      const suffix5 = img === "xls";
      const suffix6 = img === "xlsx";
      const suffix7 = img === "rar";
      const suffix8 = img === "zip";
      if (
        !suffix &&
        !suffix2 &&
        !suffix3 &&
        !suffix4 &&
        !suffix5 &&
        !suffix6 &&
        !suffix7 &&
        !suffix8
      ) {
        this.$message.error(
          "只能上传后缀为jpg、png、jpeg、pdf、xls、xlsx、rar、zip的文件!"
        );
        return;
      }
      const formData = new FormData();
      formData.append("Files", data);
      uploadAttachment(formData).then((res) => {
        if (res.status === 200) {
          this.UploadingDocument5 =
            process.env.VUE_APP_SERVER + "upload/" + res.response;
        }
      });
    },
    download(data) {
      location.href = data;
    },
  },
  created() {
    getProjectAll().then((res) => {
      if (res.status === 200) {
        this.projectList = res.response;
      }
    });
    getCompanyAll().then((res) => {
      if (res.status === 200) {
        this.companyList = res.response;
      }
    });
    //国籍
    getGeneralBasic().then((res) => {
      if (res.status === 200) {
        this.nationalityList = res.response.Countries;
        this.Marriages = res.response.Marriages;
      }
    });

    allPosition().then((res) => {
      if (res.status === 200) {
        this.positionList = res.response;
      }
    });

    // 全部岗位类别
    allPositionCategory().then((res) => {
      if (res.status === 200) {
        this.positionCategory = res.response;
      }
    });

    getEmploymentFormAll().then((res) => {
      if (res.status === 200) {
        this.employmentForm = res.response;
      }
    });
    getPositionLevelAll().then((res) => {
      if (res.status === 200) {
        this.positionLevel = res.response;
      }
    });
  },
};
</script>
<style lang="scss" scoped>
.customize-main {
  width: 100%;
  color: #000;
  .teding {
    max-width: 100%;
  }
  .tbale-img1 {
    max-width: 120px;
  }
  .customize-title1 {
    font-size: 24px;
    text-align: center;
    color: #000;
    font-weight: bolder;
  }
  .customize-herder {
    display: flex;
    justify-content: space-around;
    font-size: 16px;
    color: #000;
    height: 36px;
    align-items: center;
    margin-top: 12px;
  }
  .customize-footer {
    display: flex;
    justify-content: space-around;
    font-size: 16px;
    color: #000;
    height: 36px;
    align-items: center;
    margin-top: 24px;
    .text-box {
      text-align: center;
      div {
        margin-bottom: 8px;
      }
    }
  }
  .tr-active {
    background-color: #ecf5ff;
  }
  .text-size {
    font-size: 16px;
    color: #000;
    text-align: center;
    max-width: 200px;
    .el-checkbox {
      margin: 10px 0;
    }
    span {
      margin-right: 20px;
    }
    div {
      margin: 10px 0;
    }
    .input-box {
      display: inline-block;
      width: 100px;
      border-bottom: 1px solid #000;
      margin: 10px 0;
    }
  }
  .div-box-content {
    width: 100%;
    display: flex;
    .div-box-content-left {
      width: 40%;
      padding: 10px 0;
      margin-right: 5%;
      flex-shrink: 0;
      line-height: 20px;
      p {
        margin-top: 20px;
      }
    }
    .div-box-content-right {
      width: 50%;
      .content {
        text-align: center;
        font-size: 12px;
        padding: 5px 0;
      }
    }
  }
  .text-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    div {
      width: 50%;
    }
    .sign-box {
      display: flex;
      align-items: center;
    }
  }
  .customize-form {
    font-size: 11px;
    width: 100%;
    border-left: 1px solid black;
    border-top: 1px solid black;
    margin-top: 12px;
    tr td {
      border-right: 1px solid black;
      border-bottom: 1px solid black;
      min-width: 20px;
    }
    .text-center {
      text-align: center;
      font-size: 13px;
      padding: 10px 5px;
      .el-select {
        width: 100%;
      }
      .el-input {
        max-width: 100%;
      }
      .el-date-editor {
        width: 100%;
      }
      .el-cascader {
        width: 100%;
      }
      .flex {
        display: flex;
        font-size: 13px;
        color: #000;
        align-items: center;
        justify-content: space-around;
        margin: 10px 0;

        .el-checkbox {
          font-size: 18px;
          color: #000;
          margin-right: 10px;
        }
        .input-box {
          width: 100px;
          border-bottom: 1px solid #000;
          margin: 0 10px;
        }
      }
      .flex2 {
        display: flex;
        font-size: 13px;
        color: #000;
        align-items: center;
        justify-content: center;
        margin: 10px 0;
        .span {
          flex-shrink: 0;
          margin-left: 30px;
        }
      }
    }
    .text-title {
      text-align: center;
      font-size: 13px;
      .title {
        height: 30px;
        line-height: 30px;
        border-bottom: 1px solid #e0e0e0;
      }
      .img-box {
        padding: 10px;
        min-height: 100px;
      }
      .img-box >>> img {
        width: 100%;
      }
    }
    .text-right {
      text-align: right;
      font-size: 13px;
      padding: 5px;
    }
    .text-left {
      text-align: left;
      font-size: 13px;
      padding: 5px;
      p {
        margin: 10px 0;
      }
    }
  }
  .customize-form2 {
    border-top: none;
    margin-top: 0;
  }
}
.shenpi {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  flex-direction: column;
  cursor: pointer;
  .shenpi-tips {
    color: #315efb;
    margin-left: 0;
    margin-top: 3px;
  }
}
.shenpi-color {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  flex-direction: row;
  color: #315efb;
  cursor: pointer;
  span {
    color: red;
    margin-left: 10px;
  }
}
</style>
<style scoped>
/* .customize-main >>> .el-input__inner { */
/* border: none; */
/* } */
.card-upload >>> .el-upload-list {
  display: none;
}
.input-box >>> .el-input__inner {
  border: none;
  padding: 0;
}
.text-size >>> .el-checkbox__input {
  margin-right: 10px;
}
.signature {
  max-width: 100px;
}
.felx-box {
  display: flex;
  align-items: center;
}
.img-box {
  max-width: 100px;
  margin-left: 10px;
}
.img-box >>> img {
  width: 100%;
}

.tr-active >>> .el-input__inner {
  background-color: #ecf5ff;
}
</style>
<style scoped>
/* .customize-main >>> .el-input__inner { */
/* border: none; */
/* } */
.input-box >>> .el-input__inner {
  border: none;
  padding: 0;
}
.text-size >>> .el-checkbox__input {
  margin-right: 10px;
}
.el-date-editor {
  width: 100%;
}
.el-checkbox >>> .el-checkbox__inner {
  background-color: #fff !important;
  border-color: #000 !important;
}
.el-checkbox >>> .el-checkbox__inner::after {
  box-sizing: content-box;
  content: "";
  border-color: #000;
}
.el-date-editor {
  max-width: 500px;
}
.sign {
  max-width: 200px;
}
.img-box {
  min-height: 50px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin-left: 0;
  display: flex;
  align-items: center;
}
.img-box > img {
  max-width: 200px;
  margin-left: 0;
}

.el-checkbox
  >>> .el-checkbox__input.is-disabled.is-checked
  .el-checkbox__inner::after {
  border-color: #000 !important;
}
.text-center >>> .el-radio__label {
  color: #000 !important;
}
.text-center >>> .el-radio__input.is-checked .el-radio__inner {
  border-color: #000;
  background: #fff;
  color: #000 !important;
}
.text-center >>> .el-radio__inner {
  border-color: #000 !important;
}
.text-center >>> .el-radio__input.is-checked .el-radio__inner::after {
  background-color: #000;
  transform: translateX(-50%) translateY(-50%) !important;
}
</style>